import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import Welcome from "components/pages/welcome"
import Servicepage from "components/pages/serve"
import ChooseUspage from "components/pages/ChooseUs"
import Customepage from "components/pages/Custome"
import "./styles.scss"
import { StaticImage } from "gatsby-plugin-image"
import HeroSvg from "../assets/svgs/heroSvg/index"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import AOS from "aos"
import "aos/dist/aos.css"
import { Helmet } from "react-helmet"
import { ProTeamMembers } from "components/pages/ourTeam"

const Home = () => {
	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
		localStorage.setItem("activePage", 0)
	}
	useEffect(() => {
		const position = window.pageYOffset
	}, [])

	return (
		<>
			<Helmet>
				<html lang="en" />
				<title> MercurySols Technologies</title>
				<meta
					name="description"
					content="Welcome to Mercury Sols - a leading web development and design company offering comprehensive solutions for
				businesses looking to establish a strong online presence. Our team of experts specializes in custom software
				development, responsive web design, e-commerce solutions, and more. With our expertise and cutting-edge
				technologies, we can help your business achieve its digital goals and stay ahead of the competition. Contact
				Mercury Sols today to learn more about our services and how we can help your business grow."
				/>
			</Helmet>
			<HeaderTwo />
			{/* <div style={{ background: "#f2f2f2" }}> */}
			<Container>
				<div className=" mt-3 row rowHero">
					<div className=" mx-auto heroSvg object">
						<HeroSvg />
					</div>
				</div>
			</Container>
			<Welcome />
			<Servicepage />
			<ChooseUspage />
			{/* <ProTeamMembers /> */}
			<Customepage />
			<div className="display" style={{ position: "relative" }}>
				<div
					style={{ position: "absolute", bottom: "0", right: "0px", width: "210px", zIndex: "-100" }}
					data-aos="fade-up"
					data-aos-once="true"
					data-aos-offset="100"
					data-aos-easing="ease-in-out"
					data-aos-duration="1000"
					data-aos-mirror="true"
				>
					<StaticImage src="../assets/images/work.png" alt="IT Company" style={{ width: "100%", height: "100%" }} />
				</div>
			</div>
			{/* </div> */}
			<Footer />
		</>
	)
}

export default Home
