import React from "react"
import * as styles from "./styles.module.scss"
import { Col, Row, Card } from "react-bootstrap"
import AOS from "aos"
import "aos/dist/aos.css"
import { CompanyPage } from "../../../constants/index"
import { navigate } from "gatsby"

export const ProTeamMembers = () => {
	const OurTeamMember = CompanyPage.ourTeams
	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	return (
		<div>
			<p onClick={() => navigate("/teamMembers")} className={styles.teamHeading}>
				Our Team
			</p>
			<Row xs={1} sm={2} md={4} className={styles.profileCardRow}>
				{OurTeamMember.map((team, index) => {
					return (
						<Col
							key={index}
							className={styles.profileCardCol}
							data-aos="fade-up"
							data-aos-once="true"
							data-aos-offset="100"
							data-aos-easing="ease-in-out"
							data-aos-duration="1000"
							data-aos-mirror="true"
						>
							<Card className={`${styles.cardProfile} ${styles.cardProfile0}`}>
								<Card.Body>
									<div className={styles.profileImage}>
										<img
											className={styles.image}
											src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.alphacoders.com%2F499%2F499882.jpg&f=1&nofb=1"
										/>
									</div>
									<Card.Text className={styles.profileCardText}>{team.name}</Card.Text>
									<Card.Text className={styles.profileCardText}>{team.description}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					)
				})}
			</Row>
		</div>
	)
}

export const AllTeam = () => {
	return (
		<>
			<p>ckjcxxzizxiuzxcii</p>
			<p>ckjcxxzizxiuzxcii</p>
			<p>ckjcxxzizxiuzxcii</p>
		</>
	)
}
